import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { cn } from "@/lib/utils.ts";
import { useTranslation } from "react-i18next";
import { useAuthUser, useGetCustomerPoints } from "@/hooks";

export const UserPoints = () => {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();

  const points = useGetCustomerPoints({
    id: authUser?.uid || "",
  });

  return (
    <Card
      className={cn(
        "bg-brand-thistle rounded-xl border flex items-center sm:max-w-96 sm:ml-auto",
      )}
    >
      <CardHeader className={"flex flex-col space-y-1.5 p-6"}>
        <CardTitle className={"flex text-brand-black gap-1 items-center"}>
          {points.data?.points || 0}
          <Icons.star />
        </CardTitle>
      </CardHeader>
      <CardContent className={"p-6 pl-0"}>
        <CardDescription className={"font-hkgwb uppercase text-brand-black"}>
          {t("userPoints")}
        </CardDescription>
        <CardDescription className={"font-light text-brand-black/60"}>
          {t("spentPoints")}
        </CardDescription>
      </CardContent>
    </Card>
  );
};
